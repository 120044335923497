import React from "react"

const TCColumn = ({ comp1, comp2 }) => {
  return (
    <div className="lg:grid lg:grid-cols-2 gap-10 my-24 mx-2 md:mx-0 lg:my-52 lg:mx-48 justify-items-center align-middle">
      <div>{comp1}</div>
      <div>{comp2}</div>
    </div>
  )
}

export default TCColumn
