import React from "react"
import { Helmet } from "react-helmet"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Services from "../components/Services"
import TCColumn from "../components/TCColumn"
import Topic from "../components/Topic"
import Banner from "../components/Banner"
import TopHeading from "../components/TopHeading"
import "../icons/fontawesome"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marsandi</title>
        <link rel="canonical" href="https://marsandi.com" />
        <link rel="icon" href="https://marsandi.com/Marsandi-Logo-color.png" />
        <meta
          name="Description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        ​<meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" contact="@devmarsandi"></meta>
        <meta name="twitter:creator" content="@devmarsandi"></meta>
        <meta
          name="twitter:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta
          name="twitter:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          name="twitter:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        ​
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Marsandi" />
        <meta
          property="og:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta property="og:url" content="https://marsandi.com"></meta>
        <meta
          property="og:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          property="og:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        <meta property="og:image:alt" content="Logo"></meta>
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <Hero />
        <div className="mt-10 lg:mt-24 mb-10 lg:mb-40 text-customgray px-2 lg:px-0 lg:mx-20">
          <TopHeading title="What We Do Best" subtitle="" />
          <Services />
        </div>

        <TCColumn
          comp1={
            <img src="/hero-2.png" alt="Hero 2" width="700" height="auto" />
          }
          comp2={
            <Topic
              headtext="What Makes Us Different"
              bodytext="We don’t just send you a project form and return a product, we like to
        build relationships. We prefer more in-depth communication to actually
        target the areas that need improvement. We’ve realized by doing so, we
        are able to help in more areas than one."
              buttontext="Get Started"
              linkpath="/contact"
            />
          }
        />
        <TCColumn
          comp1={
            <img src="/hero-3.png" alt="Hero 3" width="700" height="auto" />
          }
          comp2={
            <Topic
              headtext="Tell Your Story"
              bodytext="Our team brings over 10 years of experience working with a variety of different industries. Whether you would like a simple informational website, an influencer blog, or an e-commerce store, we’ve got you covered."
              buttontext="Find Out More"
              linkpath="/services"
            />
          }
        />
        <div className="bg-customdarkblue grid justify-items-center align-middle">
          <img
            src="/banner-img-1.png"
            alt="Banner"
            width="1920"
            height="auto"
          />
        </div>
        <Banner />
      </section>
    </Layout>
  )
}
