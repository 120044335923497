import React from "react"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <div className="lg:grid lg:grid-cols-2 lg:gap-4 lg:mx-10 lg:px-40">
      <div className="">
        <img src="/hero-img.png" height="auto" width="700" alt="hero" />
      </div>
      <div className="mt-10 px-2 lg:mt-40 lg:ml-10">
        <h1 className="mx-2 md:mx-0 text-3xl lg:text-5xl max-w-lg text-customgray leading-relaxed">
          Bring Your Business To The New Era
        </h1>
        <h3 className="mx-2 md:mx-0 text-lg mt-2 max-w-xl text-customgray">
          We provide custom digital design and development for your business
          needs. We work with you to fix manual actions and bottleneck processes
          with fully automated workflows.
        </h3>
        <Link
          to="/services"
          className="mx-2 md:mx-0 inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-5"
        >
          See Our Services
        </Link>
      </div>
    </div>
  )
}

export default Hero
