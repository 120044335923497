import React from "react"
import { Link } from "gatsby"

const Topic = ({ headtext, bodytext, buttontext, linkpath }) => {
  return (
    <div className="text-customgray px-2 lg:px-0">
      <h2 className="text-3xl mb-5 mt-5 lg:mt-0">{headtext}</h2>
      <p className="text-md lg:text-xl w-full lg:w-4/5">{bodytext}</p>
      {buttontext !== "" && (
        <Link
          to={linkpath}
          className="inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-4 lg:mt-5"
        >
          {buttontext}
        </Link>
      )}
    </div>
  )
}

export default Topic
