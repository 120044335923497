import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faInstagramSquare,
  faInstagram,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

library.add(faInstagram, faInstagramSquare, faTwitterSquare, faLinkedin)

export default library
