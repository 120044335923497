import React from "react"

const Services = () => {
  return (
    <div className="lg:grid lg:grid-cols-3 lg:grid-rows-2 lg:justify-items-center lg:gap-10">
      <div className="mb-24 lg:mb-0">
        <div className="flex flex-col items-center">
          <img
            src="/service-1.png"
            alt="service"
            width="300"
            height="auto"
          ></img>
        </div>
        <h4 className="text-2xl font-bold mb-2 mx-2 md:mx-0">
          Branding & Logo Design
        </h4>
        <p className="w-full lg:w-80 mx-2 md:mx-0">
          Branding means everything. We provide branding from the ground up. No
          logo? No problem. Let us help you build a brand the way you envision
          it.
        </p>
      </div>
      <div className="mb-24 lg:mb-0">
        <div className="flex flex-col items-center">
          <img
            src="/service-2.png"
            alt="service"
            width="300"
            height="auto"
          ></img>
        </div>
        <h4 className="text-2xl font-bold mb-2 mx-2 md:mx-0">UI/UX Design</h4>
        <p className="w-full lg:w-80 mx-2 md:mx-0">
          We help create styles that define your business. Creating a logo is
          just the first step. We also help you take all of your ideas and
          compose extensive design guidelines including color schemes, fonts,
          and more.
        </p>
      </div>
      <div className="mb-24 lg:mb-0">
        <div className="flex flex-col items-center">
          <img
            src="/service-3.png"
            alt="service"
            width="300"
            height="auto"
          ></img>
        </div>
        <h4 className="text-2xl font-bold mb-2 mx-2 md:mx-0">
          Website Development
        </h4>
        <p className="w-full lg:w-80 mx-2 md:mx-0">
          Tired of your current website? Are you ready to upgrade to the latest
          design trends? We combine creativity with professionalism to give your
          company a new, clean look.
        </p>
      </div>
      <div className="mb-24 lg:mb-0">
        <div className="flex flex-col items-center">
          <img
            src="/service-4.png"
            alt="service"
            width="300"
            height="auto"
          ></img>
        </div>
        <h4 className="text-2xl font-bold mb-2 mx-2 md:mx-0">
          Social Media Management
        </h4>
        <p className="w-full lg:w-80 mx-2 md:mx-0">
          Want to build a social media reputation to develop trust with your
          audience? Our team does extensive research on your industries to
          create a roadmap of marketing content to better target your clients.
        </p>
      </div>
      <div className="mb-24 lg:mb-0">
        <div className="flex flex-col items-center">
          <img
            src="/service-5.png"
            alt="service"
            width="300"
            height="auto"
          ></img>
        </div>
        <h4 className="text-2xl font-bold mb-2 mx-2 md:mx-0">
          Email Marketing Management
        </h4>
        <p className="w-full lg:w-80 mx-2 md:mx-0">
          Creating a stunning email is not as easy as it looks. Design and
          readability is key to grabbing the attention of your readers. Let us
          help design and custom build your emails the way you envision it.
        </p>
      </div>
      <div className="mb-24 lg:mb-0">
        <div className="flex flex-col items-center">
          <img
            src="/service-6.png"
            alt="service"
            width="300"
            height="auto"
          ></img>
        </div>
        <h4 className="text-2xl font-bold mb-2 mx-2 md:mx-0">
          Web/Mobile Apps
        </h4>
        <p className="w-full lg:w-80 mx-2 md:mx-0">
          Sometimes a normal website just isn’t enough. We help create easy
          workflows for your clients to sign in as a user to see paid content or
          even create custom submission forms for your next sales lead.
        </p>
      </div>
    </div>
  )
}

export default Services
