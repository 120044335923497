import React from "react"

const TopHeading = ({ title, subtitle }) => {
  return (
    <div className="py-20 text-customgray h-full lg:h-4/6 text-center grid justify-items-center align-middle">
      <div className="w-full lg:w-5/12">
        <h1 className="text-3xl lg:text-4xl text-customgray">{title}</h1>
        <h3 className="text-xl mt-5 text-customgray">{subtitle}</h3>
      </div>
    </div>
  )
}

export default TopHeading
